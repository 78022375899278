import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  FfNgxLoadingScreenComponent,
  FfNgxCheckAuthHelper,
} from '@fagforbundet/ngx-components';
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs/operators';

@Component({
  imports: [RouterOutlet, FfNgxLoadingScreenComponent],
  selector: 'app-root',
  standalone: true,
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  readonly #checkAuthHelper = inject(FfNgxCheckAuthHelper);
  readonly #publicEventsService = inject(PublicEventsService);

  ngOnInit(): void {
    this.#checkAuthHelper.checkAuthAndRenew().subscribe();

    this.#publicEventsService
      .registerForEvents()
      .pipe(filter((event) => event.type === EventTypes.SilentRenewFailed))
      .subscribe(() => {
        // Reloading to have the current URL stored for redirect after authn
        location.reload();
      });
  }
}
