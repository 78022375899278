import { FfNgxControlErrorMessages } from '@fagforbundet/ngx-components';

type MyMembershipErrorName = 'someEmployer';
export type MyMembershipError = Record<MyMembershipErrorName, any>;

export const MY_MEMBERSHIP_ERROR_MESSAGES: FfNgxControlErrorMessages<MyMembershipErrorName> =
  {
    someEmployer: (error) =>
      'Du må enten velge arbeidsgiver fra listen eller registrere en ny arbeidsgiver',
  };
