import { Routes } from '@angular/router';
import { ffNgxAuthCanMatchGuard } from '@fagforbundet/ngx-components';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@modules/public/public.routes'),
  },
  {
    path: '',
    loadChildren: () => import('@modules/authenticated/authenticated.routes'),
    canMatch: [ffNgxAuthCanMatchGuard],
  },
];
