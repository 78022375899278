import { FfNgxStorageService } from '@fagforbundet/ngx-components';
import { RequestServiceInMemoryStoreNames } from '@core/enums/request-service-in-memory-store-names';

export function initializeInMemoryStoreFactory(
  storageService: FfNgxStorageService,
) {
  return (): void => {
    Object.values(RequestServiceInMemoryStoreNames as object).forEach(
      (storeName: string) => {
        storageService.useInMemoryStorage(storeName);
      },
    );
  };
}
