import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
  HttpResponse,
} from '@angular/common/http';
import { Observable, delay, of } from 'rxjs';
import { ContactFormTicket } from '@core/models/requests/proxy-api/post-contact-form/post-contact-form-response';

export const MOCK_TOKEN = new HttpContextToken<'attachment' | 'contact-form'>(
  () => 'attachment',
);

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.context.has(MOCK_TOKEN)) {
      if (request.context.get(MOCK_TOKEN) === 'attachment') {
        return of(
          new HttpResponse({
            body: {
              links: {
                self: '/v1/contact-form/attachments?filename=Tillitsvalgtnett%20Rundskriv%20SOLID%20cheatsheet.pdf',
              },
              attachment: {
                createdAt: '2024-06-06T12:04:29+00:00',
                uuid: '73126abb-374f-482b-ba48-90cab26402c1',
              },
            },
            status: 200,
          }),
        ).pipe(delay(900));
      }
      if (request.context.get(MOCK_TOKEN) === 'contact-form') {
        return of(
          new HttpResponse({
            body: {
              links: {
                self: '/v1/contact-form/attachments?filename=Tillitsvalgtnett%20Rundskriv%20SOLID%20cheatsheet.pdf',
              },
              ticket: {
                id: 1,
                url: 'hey',
              } as ContactFormTicket,
            },
            status: 200,
          }),
        ).pipe(delay(900));
      }
    }
    return next.handle(request);
  }
}
