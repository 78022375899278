import { FfNgxPiwikProConfig } from '@fagforbundet/ngx-components';
import { environment } from '@environments/environment';
import { EnvironmentType } from '@environments/assets/environment-type';

export const piwikProConfig: FfNgxPiwikProConfig = {
  autoTrackPageViews: true,
  containerId: '0ed8b5c8-2386-40e4-b0f2-f726f5af7318',
  containerUrl: 'https://fagforbundet.containers.piwik.pro',
  debug: false,
  throwIfPaqNotFound: environment.type !== EnvironmentType.DEVELOPMENT,
};
