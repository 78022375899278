import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { IdApiInterface } from '@environments/assets/environment.interface';
import { environment } from '@environments/environment';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

export const authnConfig: OpenIdConfiguration = {
  authority: EnvironmentHelper.fetchAPIBase('id'),
  clientId: (environment.apis.id as IdApiInterface).minSideWebClientId,
  ignoreNonceAfterRefresh: true,
  logLevel: environment.oidc.debug ? LogLevel.Debug : LogLevel.None,
  maxIdTokenIatOffsetAllowedInSeconds: 300,
  postLoginRoute: '/login',
  postLogoutRedirectUri: environment.frontend.postLogoutRedirectUri,
  redirectUrl: environment.frontend.redirectUrl,
  renewTimeBeforeTokenExpiresInSeconds: 60,
  responseType: 'code',
  scope: 'openid',
  silentRenew: true,
  tokenRefreshInSeconds: 50,
  triggerAuthorizationResultEvent: true,
  triggerRefreshWhenIdTokenExpired: false,
  useRefreshToken: true,
};
